<template>
    <div class="main">
        <div class="container">
            <div class="title">
                <div class="row">
                    <h1 class="col-5">
                        {{ activeLanguage.title }}
                    </h1>
                    <div class="col-7">
                        <div class="row" style="justify-content: flex-end;">
                            <router-link to="/clients/create/client" class="btn" style="margin-right: 10px;">{{ activeLanguage.createClient }}</router-link>
                            <router-link to="/clients/create/webshop" class="btn">{{ activeLanguage.createWebshop }}</router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="filter-bar-container">

            </div>
            <div class="table-bar">

            </div>
        </div>
    </div>
</template>

<script>
	import { mapGetters } from  "vuex"

	export default {
		name: "Clients",
        data() {
			return {

            }
        },
        mounted() {
            this.getClients()
        },
        methods: {
            getClients() {
            	newbillinkAxios
                    .get(`/client/list${1}/${20}`)
                    .then(({data}) => console.log(data))
                    .catch(err => console.error(err))
            }
        },
		computed: {
			...mapGetters({
				activeLanguage: 'getClientsLanguage',
			})
		},
	}
</script>

<style scoped>

</style>